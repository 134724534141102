/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Footer } from 'flowbite-react';
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter,BsLinkedin,BsYoutube,BsSkype,BsPinterest,BsTelegram } from 'react-icons/bs';
import {Link} from "react-router-dom";

function Footersection() {
  return (
   <>
 
    <Footer className='bg-indigo-900' >
      <div className="w-full">
        <div className="grid w-full grid-cols-2 gap-8 px-6 py-8 md:grid-cols-4">
          <div>
            <Footer.Title className='text-white' title="Company" />
            <Footer.LinkGroup className='text-white' col>
           
         <Link onClick={() => { window.scroll({ top: 0, left: 0, behavior: "smooth", }); }} to="/About" >   <Footer.Link > About</Footer.Link> </Link>  


         <Link onClick={() => { window.scroll({ top: 0, left: 0, behavior: "smooth", }); }} to="/Jobs" >  
           <Footer.Link  >Careers</Footer.Link></Link> 
              
            <Link onClick={() => { window.scroll({ top: 0, left: 0, behavior: "smooth", }); }} to="/Blog" >  
            <Footer.Link  >
                Blog</Footer.Link></Link> 
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title className='text-white' title="help center" />
            <Footer.LinkGroup className='text-white' col>
              <Footer.Link href="mailto:support@hickter.com">Support </Footer.Link> 

              <Link onClick={() => { window.scroll({ top: 0, left: 0, behavior: "smooth", }); }} to="/Contact" > 
              <Footer.Link >Contact Us</Footer.Link></Link>


              <Footer.Link href="mailto:contact@hickter.com" > Mail </Footer.Link>
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title className='text-white' title="legal" />
            <Footer.LinkGroup className='text-white' col>
            <Link onClick={() => { window.scroll({ top: 0, left: 0, behavior: "smooth", }); }} to="/Privacy" > 
              <Footer.Link >Privacy Policy</Footer.Link> </Link>
              <Link onClick={() => { window.scroll({ top: 0, left: 0, behavior: "smooth", }); }} to="/Terms" > 
              <Footer.Link >Terms &amp; Conditions</Footer.Link></Link>
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title className='text-white' title="Our Plans " />
            <Footer.LinkGroup className='text-white' col>
              
            <Link onClick={() => { window.scroll({ top: 0, left: 0, behavior: "smooth", }); }} to="/Startup_plan_hickter" > 
            <Footer.Link >Startup Plan </Footer.Link></Link>


            <Link onClick={() => { window.scroll({ top: 0, left: 0, behavior: "smooth", }); }} to="/Business_plan_hickter" > 

              <Footer.Link > Business Plan </Footer.Link>  </Link>

              <Link onClick={() => { window.scroll({ top: 0, left: 0, behavior: "smooth", }); }} to="/Corporate_plan_hickter" > 
              <Footer.Link > Corporate Plan </Footer.Link>  </Link>

              <Link onClick={() => { window.scroll({ top: 0, left: 0, behavior: "smooth", }); }} to="/Custom_plan_hickter" > 
              <Footer.Link > Custom Plan  </Footer.Link>  </Link>
            </Footer.LinkGroup>
          </div>
        </div>
        <div className="w-full bg-blue-800 bg-opacity-100 px-4 py-6 sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright className='text-white' href="mailto:support@hickter.com"  target="_blank" by="Hickter " year={2024} /><br></br>
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon className='text-white' href="https://www.facebook.com/hickter/" icon={BsFacebook} />
            <Footer.Icon className='text-white' href="https://www.instagram.com/hickterofficial/" icon={BsInstagram} />
            <Footer.Icon className='text-white' href="https://twitter.com/hickterofficial" icon={BsTwitter} />
            <Footer.Icon className='text-white' href="https://www.youtube.com/channel/UC_G8P3qpxRzJTn27nWZ6lDQ" icon={BsYoutube} />
            <Footer.Icon className='text-white' href="https://in.linkedin.com/company/hickter" icon={BsLinkedin} />
            <Footer.Icon className='text-white' href="https://t.me/hickter" icon={BsTelegram} />
            <Footer.Icon className='text-white' href="https://in.pinterest.com/hickter/" icon={BsPinterest} />
            <Footer.Icon className='text-white' href="https://join.skype.com/invite/mowrCrqLYYbV" icon={BsSkype} />
          </div>
        </div>
      </div>
    </Footer>
  

   </>
  )
}

export default Footersection