/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../views/Home/Navbarsection'
import Footersection from '../views/Home/Footersection'
import Plans from '../views/Home/Plans'

export default function Our_plan_hickter() {
  return (
   <>
   <Navbarsection/>
<Plans/>
   <Footersection/>
   </> 
  )
}
