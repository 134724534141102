/* eslint-disable react/no-unknown-property */
import { Accordion } from 'flowbite-react';

export default function Faqsection() {
  return (
   <>
   <br></br>
   <div class="mx-auto">
      <div  class="mt-[6px] text-gray-600 body-font  ">
      <h3 class="text-center lg:text-[22px] font-bold sm:text-3xl"> FAQ     </h3>
        </div>
      </div>

  <Accordion className="p-4" >
      <Accordion.Panel >
        <Accordion.Title className=" text-blue-900 font-bold" >What is Hickter ?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          Hickter ¦ Healing Life Problems From 
Technology & Internet.. We Develop Innovative Products ( Apps, Web & Gadgets ) from Technologies and Internet. At Hickter we Believe latest technologies can solve real human problems in Better way that being solved never before. Great ideas are nothing till it implemented and created. Join us if u trust on Innovation and Building Cool Products.
          </p>
           
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title className=" text-blue-900 font-bold" > Why You Choose Hickter ?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            
Get Your App & Website not only that a complete package on Hickter at very affordable price . So anyone can start tech integrated business or upgrade your existing business.
          </p>
        
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>

        <Accordion.Title className=" text-blue-900 font-bold" >  How to Apply On Hickter for any Development Services ?  </Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          Just Visit <a className='font-bold text-[16px] text-green-700' href='/Our_services_hickter'>
    Apply | Our Services at Hickter </a>Fill The Form & Get Update Soon For Process. 
         
          </p>
             
        </Accordion.Content>
      </Accordion.Panel>

      <Accordion.Panel>

<Accordion.Title className=" text-blue-900 font-bold" > How To Apply For Startup and all Plans at Hickter ?  </Accordion.Title>
<Accordion.Content>
  <p className="mb-2 text-gray-500 dark:text-gray-400">
  Just Visit <a className='font-bold text-[16px] text-green-700' href='/Our_plan_hickter'>
    Apply | Our Plans at Hickter </a>Fill The Form & Get Update Soon for Process . 
 
  </p>
    
   
</Accordion.Content>
</Accordion.Panel>

 
    </Accordion>
   </>
  )
}
 