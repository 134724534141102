 /* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
  
import Navbarsection from "../views/Home/Navbarsection" 
import Footersection from "../views/Home/Footersection"
 
import Blogsection from "../views/Home/Blogsection"

export default function blog() {
  return (
<>
 
<Navbarsection /> 
<Blogsection />
<Footersection />
   
</>
  )
}
 