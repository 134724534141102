/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react"; 
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
 import Box from '@mui/material/Box';
  import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
  import Avatar from '@mui/material/Avatar';
  import Stack from '@mui/material/Stack';
import Navbarsection from '../views/Home/Navbarsection';
import ScrollToTop from 'react-scroll-to-top';

 function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    return (
   <>
    
   <Box  
   sx={{ width: '100%', typography: 'body1' }}  >
     
      <AppBar className="z-[-5]" position="static" > 

       <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable" 
        indicatorColor="secondary"
        allowScrollButtonsMobile
        centered
        scrollButtons="auto" 

           textColor="inherit"
           aria-label="full width tabs example"
        >
 
          <Tab  icon={<Avatar alt="test avatar" 
          src="https://banner2.cleanpng.com/20180504/ocw/avdmwp8cv.webp" 
          sx={{ width: 33, height: 33 }} />} iconPosition="start" 
           label="Wordpress" {...a11yProps(0)} />

          <Tab   icon={<Avatar alt="test avatar" 
          src="./assets/icons/portfoliotabs/shopify.png" 
          sx={{ width: 33, height: 33 }} />} iconPosition="start" 
           label="Shopify" {...a11yProps(1)} />

           <Tab icon={<Avatar alt="test avatar" 
          src="./assets/icons/portfoliotabs/react.png" 
          sx={{ width: 33, height: 33 }} />} iconPosition="start" 
           label="React"   {...a11yProps(2)} />


          <Tab icon={<Avatar alt="test avatar" 
          src="./assets/icons/portfoliotabs/laravel.png" 
          sx={{ width: 33, height: 33 }} />} iconPosition="start" 
           label="Laravel"    {...a11yProps(3)} />


          <Tab icon={<Avatar alt="test avatar" 
          src="./assets/icons/portfoliotabs/php.png" 
          sx={{ width: 33, height: 33 }} />} iconPosition="start" 
           label="php"  {...a11yProps(4)} />


          <Tab icon={<Avatar alt="test avatar" 
          src="./assets/icons/portfoliotabs/html.png" 
          sx={{ width: 33, height: 33 }} />} iconPosition="start" 
           label="HTML"  {...a11yProps(5)} />


          <Tab  icon={<Avatar alt="test avatar" 
          src="./assets/icons/portfoliotabs/apps.png" 
          sx={{ width: 33, height: 33 }} />} iconPosition="start" 
           label="App Projects"  {...a11yProps(6)} />


          <Tab icon={<Avatar alt="test avatar" 
          src="./assets/icons/portfoliotabs/project.png" 
          sx={{ width: 33, height: 33 }} />} iconPosition="start" 
           label="All Themes & Projects"  {...a11yProps(7)} />


        </Tabs>
        
      </AppBar>

       {/*Wordpress    */}
      <TabPanel value={value} index={0} dir={theme.direction}>

      {/* 1st Section */}


 <div className="mt-[2px]">
   <h2 className=" w-1/1 p-5 mx-auto text-center font-bold text-[18px]"> Our Portfolio - Works </h2>

  </div>


  <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
      <div className="grid gap-5 lg:grid-cols-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
          {/* Bhumiratna  */}

   <section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/Bhumiratna.webp"
          alt="Bhumiratna" className="w-full max-h-[365px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Bhumiratnaagriscience | Agriculture Products
        </Typography>
        <Typography>
        Micro Nutrient Fertilizer ,Soil Fungus And Becterial Control in India. The supplier company is located in PUNE, Maharashtra and is one of the leading sellers of listed products. 
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="ml-[12px] flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-css3"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://www.bhumiratnaagriscience.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  <td> <a href="https://play.google.com/store/apps/details?id=com.bhumiratnaagriscience.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Pimpri , Pune ( India )
                </div>
            </div>
        </div>
    </article>
</section>

</div>


        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
           {/* startreliable  */}
<section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/st.webp"
          alt="Bhumiratna" className="w-full max-h-[320px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Star Reliable Book Keeping & Payroll Services
        </Typography>
        <Typography>
        We work with quickbooks, xero, axon, and sage 50. We work 24/7 so you can reach out to us anytime.Maintaining a business’s financial records.  
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-css3"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://www.starreliablebookkeepingandpayrollservices.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/* <td> <a href="https://play.google.com/store/apps/details?id=com.bhumiratnaagriscience.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td> */}
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Saskatoon SK  ( Canada )
                </div>
            </div>
        </div>
    </article>
</section>

 
        </div>
        
      </div>
    </div>


  
 {/* 2nd Section */}

    <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
      <div className="grid gap-5 lg:grid-cols-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
          {/* Hotel Prin  */}

   <section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
   <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/hp.webp"
          alt="Bhumiratna" className="w-full max-h-[320px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
       Hotel Prince |  Hotel & Room Booking 
        </Typography>
        <Typography>
        This hotel in Odisha offer a number of benefits to customers. They are affordable, convenient, safe, and supportive. They also offer a number of specific benefits. 
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>
 

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-css3"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://m-hotelprince.web.app/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/* <td> <a href="https://play.google.com/store/apps/details?id=com.bhumiratnaagriscience.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td> */}
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Bhagya Nagar Colony Rd, Hyderabad  ( India )
                </div>
            </div>
        </div>
    </article>
</section>

</div>


        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
           {/* startreliable  */}
<section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
<article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/Isyx Tech.webp"
          alt="Bhumiratna" className="w-full max-h-[320px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        ISYX Tech – Tech Solution
        </Typography>
        <Typography>
        The company was founded in 2011 . 10+ Global Offices, we help businesses to impact on their customers by designing, implementing and managing infrastructure. 

         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>
 
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-css3"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://isyxtech.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/* <td> <a href="https://play.google.com/store/apps/details?id=com.bhumiratnaagriscience.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td> */}
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Media City,
Dubai ( United Arab Emirates )
                </div>
            </div>
        </div>
    </article>
</section>
  </div>
        
      </div>
    </div>

 
 {/* 3rd Section */}

 

    <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
      <div className="grid gap-5 lg:grid-cols-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
          {/* Fiji  */}

   <section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
   <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/fiji.webp"
          alt="Bhumiratna" className="w-full max-h-[320px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Fiji Water – Brand Site
        </Typography>
        <Typography>
        FIJI Water has been committed to doing business responsibly and seeking opportunities to make a difference. Local communities, while focusing on meaningful issues. 
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>
 
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-css3"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://www.fijiwater.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/* <td> <a href="https://play.google.com/store/apps/details?id=com.bhumiratnaagriscience.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td> */}
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Blvd Floor, 
Los Angeles ( CA )
                </div>
            </div>
        </div>
    </article>
</section>

</div>


        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
           {/*  Brother Printer  */}
<section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
<article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/BT.png"
          alt="" className="w-full max-h-[320px] object-cover md:w-52"  />
           
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Brother Printer Service Center |  Printing Services 
        </Typography>
        <Typography>
        We Have Well Qualified Specialists To Handle Your Toner Cartridges With Excessive Care Of Your Printers.  </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fa-brands fa-js"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://brotherprinterservicecenter.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/*  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  */} 
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Al Awamer ( Qatar )
                </div>
            </div>
        </div>
    </article>
</section>
  </div>
        
      </div>
    </div>



{/* 4th Section */}

 

<div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
      <div className="grid gap-5 lg:grid-cols-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
          {/* Voxtron  */}

   <section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
   <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/voxtron.webp"
          alt="Bhumiratna" className="w-full max-h-[320px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Voxtron | Digital Solution 
        </Typography>
        <Typography>
        Application development company with 20+ years of experience, we build highly secure, scalable and robust applications for global businesses. 
 
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fa-brands fa-js"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://www.voxtronme.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/* <td> <a href="https://play.google.com/store/apps/details?id=com.bhumiratnaagriscience.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td> */}
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Mattar Street, Najma, Airport Road Doha ( Qatar )
                </div>
            </div>
        </div>
    </article>
</section>

</div>


        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
           {/*   Money Vision */}
<section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
<article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/mv.webp"
          alt="Bhumiratna" className="w-full max-h-[320px] object-cover md:w-52"  />
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' > 
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Money Vision | Mutual Fund
        </Typography>
        <Typography>
        Mutual fund apps offer a number of benefits to investors of all experience levels. They provide a convenient, simple, and affordable way to Invest.
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fa-brands fa-js"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://moneyvision.in/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
   <td> <a href="https://play.google.com/store/apps/details?id=com.moneyvisionappofficial.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Jharsuguda, Odisha ( India )
                </div>
            </div>
        </div>
    </article>
</section>
  </div>
        
      </div>
    </div>





{/* 5th Section */}

 

<div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
      <div className="grid gap-5 lg:grid-cols-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
          {/* Perfume  */}

   <section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
   <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/Luxury Perfume.webp"
          alt="Bhumiratna" className="w-full max-h-[320px] object-cover md:w-52"  />
           
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Luxury Perfume | Brand 
        </Typography>
        <Typography>
        This is the best place to buy perfumes and colognes online. We offer a wide selection of fragrances from top brands, including Chanel, Dior, Gucci,etc.
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fa-brands fa-js"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://luxuryperfume.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/*  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  */} 
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Broadway,  ( Los Angeles )
                </div>
            </div>
        </div>
    </article>
</section>

</div>


        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
           {/*   MyHome */}
<section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
<article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/mh.webp"
          alt="Bhumiratna" className="w-full max-h-[320px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        MyHome | Real-Estate Booking


        </Typography>
        <Typography>
        Real estate apps offer a number of benefits to both buyers and sellers. For buyers, real estate apps can make the home buying process more convenient, personalized, and transparent. 
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fa-brands fa-js"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://bookmyfirsthome.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
   <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Mattar Street, Najma, Airport Road Doha ( Qatar )
                </div>
            </div>
        </div>
    </article>
</section>
  </div>
        
      </div>
    </div>



{/* 5th Section */}
 
<div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
      <div className="grid gap-5 lg:grid-cols-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
          {/* Auragoa  */}

   <section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
   <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/Auragoa.webp"
          alt="" className="w-full max-h-[320px] object-cover md:w-52"  />
           
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Auragoa | SPA & Living
        </Typography>
        <Typography>
        This wellness oasis is an hour and a half’s drive from Goa International Airport. Arambol beach, also very close to the property options Available .
 
 
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fa-brands fa-js"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://auragoa.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/*  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  */} 
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Mandrem, Goa ( India )
                </div>
            </div>
        </div>
    </article>
</section>

</div>


        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
           {/*   FRATERNITY TECHNOCRATS */}
<section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
<article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/ft.webp"
          alt="" className="w-full max-h-[320px] object-cover md:w-52"  />
           
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        FRATERNITY TECHNOCRATS | Solar Renewal Energy
        </Typography>
        <Typography>
        Fraternity Technocrats encourage our community to be environmentally conscious and to preserve our world for ourselves and future generations. FT is dedicated to enlightening society. 

         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fa-brands fa-js"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://www.fraternitytechnocrats.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/*  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  */} 
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Bhubaneswar, Odisha  ( India )
                </div>
            </div>
        </div>
    </article>
</section>
  </div>
        
      </div>
    </div>




{/* 6th Section */}
 
<div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
      <div className="grid gap-5 lg:grid-cols-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
          {/*  Jersey Group   */}

   <section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
   <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/Jersey Group.png"
          alt="" className="w-full max-h-[320px] object-cover md:w-52"  />
           
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Jersey Group |  Projects Builder For National Recognition
        </Typography>
        <Typography>
        Jersey Group is one amongst the foremost group of companies offering world leading MEP products & services, firmly established in Qatar. 
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fa-brands fa-js"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://www.jerseygroup.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/*  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  */} 
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Al Awamer ( Qatar )
                </div>
            </div>
        </div>
    </article>
</section>

</div>


        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
           {/*   Radhika Raj Purohit */}
<section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
<article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/rrp.png"
          alt="" className="w-full max-h-[320px] object-cover md:w-52"  />
           
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
       Radhika Raj Purohit | clothing store
        </Typography>
        <Typography>
        Best clothing store for young ladies , gents and kids. shop online with home 
        delivery falities you get from best fashion designer person. 

         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fa-brands fa-js"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://radhikarajpurohit.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/*  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  */} 
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Bhubaneswar, Odisha  ( India )
                </div>
            </div>
        </div>
    </article>
</section>
  </div>
        
      </div>
    </div>


      </TabPanel>


{/* Shopify  Themes*/}
<TabPanel value={value} index={1} dir={theme.direction}>
     

     {/* 3rd Section */}

 

    <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
      <div className="grid gap-5 lg:grid-cols-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
          {/* Fiji  */}

   <section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
   <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/fiji.webp"
          alt="Bhumiratna" className="w-full max-h-[320px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Fiji Water – Brand Site
        </Typography>
        <Typography>
        FIJI Water has been committed to doing business responsibly and seeking opportunities to make a difference. Local communities, while focusing on meaningful issues. 
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>
 
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-css3"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://www.fijiwater.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/* <td> <a href="https://play.google.com/store/apps/details?id=com.bhumiratnaagriscience.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td> */}
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Blvd Floor, 
Los Angeles ( CA )
                </div>
            </div>
        </div>
    </article>
</section>

</div>


        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
           {/*  pontualaesthetics    */}
<section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
<article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/ph.png"
          alt="" className="w-full max-h-[320px] object-cover md:w-52"  />
           
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
         Pontualaesthetics |  Manufacturing of Healtcare
        </Typography>
        <Typography>
        We are a Brazilian industry specialized in the manufacture of furniture for tattooing, micropigmentation, aesthetics, physiotherapy and dermatology with a mission to transform and revolutionize.  </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fa-brands fa-js"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://pontualaesthetics.com/ " target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/*  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  */} 
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Al Awamer ( Qatar )
                </div>
            </div>
        </div>
    </article>
</section>
  </div>
        
      </div>
    </div>
 
      </TabPanel>

 
 {/* React */}
 <TabPanel value={value} index={2} dir={theme.direction}>
      {/* 4th Section */}

 

<div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
      <div className="grid gap-5 lg:grid-cols-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
          {/* mahakalsocialwelfaresociety  */}

   <section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
   <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/mh.png"
          alt="Mahakal" className="w-full max-h-[320px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
         Mahakal Socialwelfare Society 
        </Typography>
        <Typography>
        Social welfare societies are non-profit organizations that work to improve the lives of individuals and communities.
 
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fa-brands fa-js"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://mahakalsocialwelfaresociety.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/* <td> <a href="https://play.google.com/store/apps/details?id=com.bhumiratnaagriscience.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td> */}
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Mattar Street, Najma, Airport Road Doha ( Qatar )
                </div>
            </div>
        </div>
    </article>
</section>

</div>


        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
           {/*   Money Vision */}
<section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
<article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/mv.webp"
          alt="Bhumiratna" className="w-full max-h-[320px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Money Vision | Mutual Fund

        </Typography>
        <Typography>
        Mutual fund apps offer a number of benefits to investors of all experience levels. They provide a convenient, simple, and affordable way to Invest.
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fa-brands fa-js"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://moneyvision.in/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
   <td> <a href="https://play.google.com/store/apps/details?id=com.moneyvisionappofficial.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Jharsuguda, Odisha ( India )
                </div>
            </div>
        </div>
    </article>
</section>
  </div>
        
      </div>
    </div>

      </TabPanel>


 
 {/* Laravel  Themes*/}
      <TabPanel value={value} index={3} dir={theme.direction}>
      Comming Soon 
      </TabPanel>

{/* php  Themes*/}
      <TabPanel value={value} index={4} dir={theme.direction}>
{/* 6th Section */}
 
<div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
      <div className="grid gap-5 lg:grid-cols-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
          {/*  Jersey Group   */}

   <section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
   <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/Jersey Group.png"
          alt="" className="w-full max-h-[320px] object-cover md:w-52"  />
           
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Jersey Group |  Projects Builder For National Recognition
        </Typography>
        <Typography>
        Jersey Group is one amongst the foremost group of companies offering world leading MEP products & services, firmly established in Qatar. 
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fa-brands fa-js"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://www.jerseygroup.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/*  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  */} 
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Al Awamer ( Qatar )
                </div>
            </div>
        </div>
    </article>
</section>

</div>


        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
           {/*   Radhika Raj Purohit */}
<section className="mt-[-3px] container mx-auto p-4 md:p-4 antialiased ">
<article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/Luxury Perfume.webp"
          alt="Bhumiratna" className="w-full max-h-[320px] object-cover md:w-52"  />
           
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Luxury Perfume | Brand 
        </Typography>
        <Typography>
        This is the best place to buy perfumes and colognes online. We offer a wide selection of fragrances from top brands, including Chanel, Dior, Gucci,etc.
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="text-lg text-gray-700">
                            <span className="text-gray-900 font-bold"> Tech </span> Stack
                        </div>
                        <div className="flex items-center">
                        <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
   <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-php"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fa-brands fa-js"></i>
   </span>

 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-html5"></i>
   </span>

 
 <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
   <i style={{fontSize:20}} className="fab fa-wordpress"></i>
   </span>

</div>   </div>
                    </div>
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://luxuryperfume.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/*  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  */} 
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Broadway,  ( Los Angeles )
                </div>
            </div>
        </div>
    </article>
</section>
  </div>
        
      </div>
    </div>


      </TabPanel>

      {/* HTML  Themes*/}
      <TabPanel value={value} index={5} dir={theme.direction}>
        Comming Soon 
      </TabPanel>

      {/* App Projects */}
      <TabPanel value={value} index={6} dir={theme.direction}>
      Comming Soon 
      </TabPanel>


       {/* All Themes & Projects */}
      <TabPanel value={value} index={7} dir={theme.direction}>
      Comming Soon 
      </TabPanel>
    </Box>
 

 <ScrollToTop/>
   </>
  )
}
