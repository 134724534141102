/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../views/Home/Navbarsection'
import Footersection from '../views/Home/Footersection'
 
export default function Website_demo() {
  return (
  <>
  <Navbarsection/>
  <div className="p-6  text-base">
 <h1  className="mx-auto text-center border  font-bold lg:text-[19px]  sm:text-3xl justify-self-auto " > Comming Soon
 
 
 
  </h1>
 </div>
 <br></br><br></br><br></br>
  <Footersection/>
  </>
  )
}
 