/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../views/Home/Navbarsection'
import Footersection from '../views/Home/Footersection'
import Pricingsection from '../views/Home/Pricingsection'
import Plans from '../views/Home/Plans'

export default function Our_services_hickter() {
  return (
   <>
   <Navbarsection/>
   <Pricingsection/>
   <Plans/>
 <Footersection/>
   </>
  )
}
