/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */

import React, { Component } from 'react'
// eslint-disable-next-line no-unused-vars
import ReactPlayer from 'react-player' 


export default function Aboutusection() {
  return (
  <> 
  <section>
  <div className="mx-auto max-w-screen-xl px-4 py-2 sm:px-6  lg:px-3 lg:py-3 lg:mb-3">
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
      <div className="relative h-64 overflow-hidden rounded-lg sm:h-8 lg:order-last lg:h-full">
     
      <div className='mr-5 player-wrapper'>
      <img
            className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
            src="./assets/banners/1.webp"
            alt=""
          />
        </div>

      </div>

      <div className="ml-6 mt-6 ">
        <h2 className="text-[18px] font-bold sm:text-4xl"> About <a className="text-blue-700" > Hickter  </a> </h2>

        <p className="mt-4 text-gray-600">
        Hickter ¦ Healing Life Problems From Technology & Internet.. We Develop Innovative Products ( Apps, Web & Gadgets ) from Technologies and Internet. At Hickter we Believe latest technologies can solve real human problems in Better way that being solved never before. Great ideas are nothing till it implemented and created. Join us if u trust on Innovation and Building Cool Products. 
 
        </p>
     
<a
  class="mt-8 inline-flex items-center gap-2 rounded border border-indigo-600 bg-indigo-600 px-8 py-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
  href="/Contact"
>
  <span class="text-sm font-medium"> 
Contact Us Now 
      </span>

  <svg
    class="h-5 w-5 rtl:rotate-180"
    xmlns="./assets/banners/1.webp"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M17 8l4 4m0 0l-4 4m4-4H3"
    />
  </svg>
</a>
 
      </div>
    </div> 
  </div>

  <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-10 lg:grid-cols-2">
      <div>
          <img
            className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
            src="./assets/banners/mm.webp"
            alt=""
          />
        </div>
        <div className="lg:pr-10">
          <a
            href="/"
            aria-label="Go Home"
            title="Logo"
            className="inline-block mb-5"
          >
            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
            <i style={{fontSize:40}} class="fa-solid fa-bullseye"></i>
            </div>
          </a>
          <h5 className="mb-4 text-4xl font-extrabold leading-none">
          Our Mission
 
          </h5>
          <p className="mb-6 text-gray-900">
          Our Mission is to Serve our client’s such a manner so that they can have successful unicorn companies from us in the best manners.

We have the vision to expand our solutions to the international level and all
around the globe. And try to innovate new technology at a greater pace so that can provide services to our clients in the best way we can.
          </p>
          <hr className="mb-5 border-gray-300" />
          
        </div>
       
      </div>
    </div>

</section>
  </>
  )
}

 