/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React, { useEffect } from "react"
import { useRef } from "react";
  import {
  Card, 
  Button,
  Typography,
} from "@material-tailwind/react";
import Glide from "@glidejs/glide"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 2000, min: 1000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 2000, min: 900 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3
  }
};
export default function Bannedadsslide() {
  return (
   <>
   <div class="mx-auto">
      <div  class="mb-[3px] text-gray-600 body-font  ">
      <h3 class="mt-[8px] text-center font-bold lg:text-[18px] sm:text-[15px]">  What u get..!⬇️
      <a target="_blank" href="/Our_Portfolio">  <Button className="bg-blue-500 ml-[25px]" >   View Clients  <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button> </a> </h3>
        </div>
      </div>
   
<Carousel responsive={responsive}  className="z-0 mt-[-8px] overscroll-x-none P-3" >
<div className="p-8"> <figure className="position:absolute   inline-block align-middle">
  <img src="https://ik.imagekit.io/hickter/Main%20File%20-%20Hickter/Assets/check-mark.png" className="h-22 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center text-[11px]" > Regular Social Posts  </figcaption>
</figure>
 </div>

 <div className="p-8"> <figure className="position:absolute   inline-block align-middle">
  <img src="https://ik.imagekit.io/hickter/Main%20File%20-%20Hickter/Assets/check-mark.png" className="h-22 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center text-[11px]" >  Great UI Design Ads </figcaption>
</figure>
 </div>

 <div className="p-8"> <figure className="position:absolute   inline-block align-middle">
  <img src="https://ik.imagekit.io/hickter/Main%20File%20-%20Hickter/Assets/check-mark.png" className="h-22 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center text-[11px]" > Editing With Latest App  </figcaption>
</figure>
 </div>

 <div className="p-8"> <figure className="position:absolute   inline-block align-middle">
  <img src="https://ik.imagekit.io/hickter/Main%20File%20-%20Hickter/Assets/check-mark.png" className="h-22 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center text-[11px]" > Ads Posting With Tags  </figcaption>
</figure>
 </div>

 
</Carousel>

   </>
  )
}
 

