/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../views/Home/Navbarsection'
import Footersection from '../views/Home/Footersection'
import Pricingsection from '../views/Home/Pricingsection'

export default function Ourpricing() {
  return (
    <>
    <Navbarsection/>
<Pricingsection/>
    <Footersection/>
    </>
  )
} 