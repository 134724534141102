/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../views/Home/Navbarsection'
import Footersection from '../views/Home/Footersection'
 import Pricingsection from '../views/Home/Pricingsection'
import Plansdetails from '../views/Home/Plansdetails'

export default function Plans_and_Pricing() {
  return (
    <>
    <Navbarsection/>
 <Plansdetails/>
 <Pricingsection/>
    <Footersection/>
    </>
  )
}
