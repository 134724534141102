/* eslint-disable no-unused-vars */
import React from 'react'
import Faqsection from '../views/Home/Faqsection'
import Navbarsection from '../views/Home/Navbarsection'
import Footersection from '../views/Home/Footersection'

export default function Faq() {
  return (
    <> 
    <Navbarsection/>
    <Faqsection/>
    <Footersection/> 
    </>
  )
}
