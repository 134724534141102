
import { useState, useEffect } from "react";
 /* eslint-disable no-unused-vars */
import React from 'react'
 import Cardsk from '../Components/Cardsk'
import Skeletonui from '../Components/Skeletonui'
import Navbarsection from "../views/Home/Navbarsection";
import Footersection from "../views/Home/Footersection";
import Iframe from 'react-iframe'

function Signupaccount() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <><Navbarsection /><div className="flex justify-center items-center h-screen">
      {loading ? <Skeletonui /> : <Iframe className="mt-[0px] mb-[5px]" url="https://apply.hickter.in/signup/"
        width="100%"
        height="460px" 
        display="block"
        position="relative"
        frameBorder="0"
        scrolling="no"/>}
    </div><Footersection />
    
     
    
    </>
  );
}

export default Signupaccount;
  