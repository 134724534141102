// eslint-disable-next-line no-unused-vars
import React from 'react'
import Iframe from 'react-iframe'

export default function Merchantsections() {
  return (
   <>
 

<section className="bg-gray-100">
  <div className="mx-auto max-w-screen-xl px-4 p-4 sm:px-6">
    <div className="grid grid-cols-1 gap-x-5 gap-y-4 lg:grid-cols-5">
      <div className="lg:col-span-2 lg:py-2">
      <p className="max-w-xl text-lg">
        <img src="https://res.cloudinary.com/dailyle-company/image/upload/v1705173283/Dailyle%20Website/Delivery%20Agent/Instant_Delivery_platform_2.png" className="h-120 w-full 
    rounded --300" alt="" /> 
        </p>

       
        <ul><br></br>
  <li>
    <a
      href="tel:917894447766"
      className="flex items-center gap-2 border-s-[3px] border-blue-500 bg-blue-50 px-4 py-3 text-red-700"
    >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605" />
</svg>




      <span className="text-sm font-medium"> 
       <a href="" className='text-pink-500 text-[14px]' > Increase Your Sale Revenue  
 </a> </span>
    </a>
  </li>

  <li>
    <a href="https://www.google.com/maps?q=Dailyle&ftid=0x3a20e3934300f6c9:0x350351fb7e3ed148"
      className="flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-gray-500 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700"
    >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
</svg>




      <span className="text-sm font-medium"> 
       <a href="https://www.google.com/maps?q=Dailyle&ftid=0x3a20e3934300f6c9:0x350351fb7e3ed148" className='text-pink-500 text-[14px]' >   <address className="mt-2 not-italic">  Reach Out To Unknown Customers </address>  </a> </span>
    </a>
  </li>

  <li>
    <a
      href="mailto:contact@dailyle.com"
      className="flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-gray-500 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700"
    >
   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
</svg>




      <span className="text-sm font-medium">  
       <a href="mailto:contact@dailyle.com" className='text-pink-500 text-[15px]' >Earn Profit From Day 1 </a> </span>
    </a>
  </li>
 
 
</ul>

       
      </div>

      <div className="rounded-lg bg-purple-900 p-3 shadow-lg lg:col-span-3 lg:p-1">
         
        <Iframe url="https://hickter.in/dailyle/deliverymerchant/merchant.html"
        width="100%"
        height="400px" 
        display="block"
        position="relative"/>

      </div>
    </div>
  </div>
</section>
   </>
  )
}
 


