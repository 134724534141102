/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
 import reportWebVitals from './reportWebVitals';
import router from "./router.js"
import { RouterProvider } from 'react-router-dom'
import ReactGa from "react-ga"
 // eslint-disable-next-line no-unused-vars
 import { useLocation } from 'react-router-dom';

const TRACKING_ID = "UA-126217949-1";
ReactGa.initialize(TRACKING_ID); 
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.createRoot(document.getElementById('root')).rendermarkerDiv.parentNode.removeChild(markerDiv)(
  
  <React.StrictMode>
<RouterProvider router={router} />
  </React.StrictMode>,
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
