/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import Cardsk from '../Components/Cardsk'
import Skeletonui from '../Components/Skeletonui'
import { useState, useEffect } from "react";

import Navbarsection from "../views/Home/Navbarsection" 
  import Footersection from "../views/Home/Footersection"
  import Iframe from 'react-iframe'

export default function Category() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);
  
  return (
<>
 
<Navbarsection />

      <div className="">

       
      {loading ? <Skeletonui />  :  <Iframe url="./jobs.html"
         width="100%"
         height="900px" 
         display="block"
         position="relative"/>   }

        {/*  Dextop Only  */}
        
 
       </div>

    
<Footersection />
 
  
  
</>
  )
}
