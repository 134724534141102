
/* eslint-disable no-unused-vars */
import React from 'react' 
import {
    CardBody,
   Typography,
  Button, 
} from "@material-tailwind/react";
 import { useState, useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'font-awesome/css/font-awesome.min.css'; 


   
export default function Banner_ads_single() {
  const [size, setSize] = React.useState(null);
 
  const handleOpen = (value) => setSize(value);
  
 const [loading, setLoading] = useState(true);

 useEffect(() => {
   setTimeout(() => {
     setLoading(false);
   }, 4300);
 }, []);
  return (
   <>
    
  {/*    End Modal Section Section  */}

   <div className="px-4 py-3 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-6 lg:py-15">
      <div className="grid gap-10 lg:grid-cols-2">
        <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
         
          <div className="max-w-xl mb-1">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
            Banner Ads | Promotion
            <br></br> 
              <span className="text-pink-500">
              At Hickter
              </span>
            </h2>
            <Typography>
            ✔ We do Marketing and Banner Ads Design . Regular Posting of Ads to Social Media Etc.
        </Typography> 
          </div>
           
          <CardBody className="bg-white" > 
          <Typography
          variant="h1"
          color="black"
          className="mt-0 flex justify-center gap-1 text-7xl font-normal"><span className="mb-0 text-orange-400 font-bold self-end text-[14px]"> Start <br></br>From  </span> 
          <span className="mt-0 text-[32px]">₹5,000 </span>{""}
          <span className="self-end text-[14px]"> / Month </span>  
        </Typography> <br></br>
       
        <Typography>
        Features | Lists
        </Typography>
<br></br>
<Typography className="mt-0 text-center">
        <div className="flex gap-1">
 
        <div className="bg-blue-200 auto p-2 mt-1 mb-1 text-center text-black rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="ml-3 text-center p-1 h-12 w-15"
          src="./assets/icons/sr/1i.webp"
          alt=""
        /> <p className="text-[13px]" > Canva Design  </p>
      </div> 

<div className="bg-blue-200 auto p-2 mt-1 mb-1 text-center text-black rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="text-center   p-1 h-12 w-15"
          src="./assets/icons/sr/i2.webp"
          alt=""
        /> <p className="text-[13px]" > Photoshop Design  </p>
      </div> 

<div className="bg-blue-200 auto p-2 mt-1 mb-1 text-center text-black rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="text-centerp-1 h-12 w-15"
          src="./assets/icons/sr/3i.webp"
          alt=""
        /> <p className="text-[13px]" > Ads Design  </p>
      </div> 
 
      <div className="bg-blue-200 auto p-2 mt-1 mb-1 text-center text-black rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10" >
      <img className="text-center p-2  h-12 w-15"
          src="./assets/icons/sr/4i.webp"
          alt=""
        /> 
        <p className="text-[13px]" > Ads Posting </p>
      </div>
 
 </div>
 
        </Typography>
  
      </CardBody>
          <div>
          <a href="/Banner_ads__apply">  
          
          <Button 
             className="ml-[21px] text-center flex items-center font-semibold transition-colors duration-200 bg-blue-600 text-white p-3 rounded hover:text-deep-purple-800"
           >
             <span className="cursor-pointer rounded-full  ">
           <i style={{fontSize:18}} className="fa-solid fa-check-double ml-3 p-2"></i>
           </span> Submit Your Request
             <svg
               className="inline-block w-3 ml-2"
               fill="currentColor"
               viewBox="0 0 12 12"
             >
               <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
             </svg>  
           </Button></a>
          </div>

        </div>
        <div className="flex items-center justify-center -mx-4 lg:pl-8">
          <div className="flex flex-col items-end px-3">
            <img
              className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
              src="./assets/services/bannerads/bk.webp"
              alt=""
            />
            <img
              className="object-cover w-55 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
              src="./assets/services/bannerads/3b.webp"
              alt=""
            />
          </div>
          <div className="px-3">
            <img
              className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
              src="./assets/services/bannerads/2b.webp"
              alt=""
            /> 
          </div>
           
        </div>
      </div>
    </div>
 
   </>
  )
}
 
 