/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React, { useEffect } from "react"
import { useRef } from "react";
  import {
  Card, 
  Button,
  Typography,
} from "@material-tailwind/react";
import Glide from "@glidejs/glide"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 2000, min: 1000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 2000, min: 900 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3
  }
};
export default function Logoslidestartup() {
  return (
   <>
   <div class="mx-auto">
      <div  class="mb-[3px] text-gray-600 body-font  ">
      <h3 class="mt-[8px] text-center font-bold lg:text-[18px] sm:text-[15px]">  What u get..!⬇️
      <a target="_blank" href="/Our_Portfolio">  <Button className="bg-blue-500 ml-[25px]" >   View Clients  <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button> </a> </h3>
        </div>
      </div>
   
<Carousel responsive={responsive}  className="z-0 mt-[-8px] overscroll-x-none P-3" >
<div className="p-8"> <figure className="position:absolute   inline-block align-middle">
  <img src="https://ik.imagekit.io/hickter/Main%20File%20-%20Hickter/Assets/check-mark.png" className="h-22 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center text-[11px]" > Website Development  </figcaption>
</figure>
 </div>


 <div className="p-8"> <figure className=" position:absolute   inline-block align-middle">
  <img src="https://ik.imagekit.io/hickterapp/Hickter.com/Icons/smartphone%20(1).png?updatedAt=1714327954741" className="h-22 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center text-[11px]" > App Development  </figcaption>
</figure>
 </div>

 <div className="p-8"> <figure className=" position:absolute   inline-block align-middle">
  <img src="https://ik.imagekit.io/hickterapp/Hickter.com/Icons/online-shop.png?updatedAt=1714327956979" className="h-22 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center text-[11px]" > Website Design   </figcaption>
</figure>
 </div>

 <div className="p-8"> <figure className=" position:absolute   inline-block align-middle">
  <img src="https://ik.imagekit.io/hickterapp/Hickter.com/Icons/social-media%20(1).png?updatedAt=1714327971789" className="h-22 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center text-[11px]" > Digital Marketing  </figcaption>
</figure>
 </div>

 <div className="p-8"> <figure className=" position:absolute   inline-block align-middle">
  <img src="https://ik.imagekit.io/hickterapp/Hickter.com/Icons/ads.png?updatedAt=1714376384752" className="h-22 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center text-[11px]" > Banner Ads  </figcaption>
</figure>
 </div>

 <div className="p-8"> <figure className=" position:absolute   inline-block align-middle">
  <img src="https://ik.imagekit.io/hickterapp/Hickter.com/Icons/multimedia.png?updatedAt=1714327947841" className="h-22 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center text-[11px]" > Video Ads  </figcaption>
</figure>
 </div>

 <div className="p-8"> <figure className=" position:absolute   inline-block align-middle">
  <img src="https://ik.imagekit.io/hickterapp/Hickter.com/Icons/online-shop.png?updatedAt=1714327956979" className="h-22 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center text-[11px]" > Ecommerce Site   </figcaption>
</figure>
 </div>

 <div className="p-8"> <figure className=" position:absolute   inline-block align-middle">
  <img src="https://ik.imagekit.io/hickterapp/Hickter.com/Icons/seo%20(1).png?updatedAt=1714328136668" className="h-22 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center text-[11px]" > SEO Marketing   </figcaption>
</figure>
 </div>

 <div className="p-8"> <figure className=" position:absolute   inline-block align-middle">
  <img src="https://ik.imagekit.io/hickterapp/Hickter.com/Icons/domain%20(1).png?updatedAt=1714327962085" className="h-22 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center text-[11px]" > Domain   </figcaption>
</figure>
 </div>

 <div className="p-8"> <figure className=" position:absolute   inline-block align-middle">
  <img src="https://ik.imagekit.io/hickterapp/Hickter.com/Icons/windows-dedicated-server-hosting-india.png?updatedAt=1714396550532" className="h-22 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center text-[11px]" > Hosting    </figcaption>
</figure>
 </div>

 <div className="p-8"> <figure className=" position:absolute   inline-block align-middle">
  <img src="https://ik.imagekit.io/hickterapp/Hickter.com/Icons/professional-training.png?updatedAt=1714327995623" className="h-22 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center text-[11px]" > Company Formation     </figcaption>
</figure>
 </div>
</Carousel>

   </>
  )
}

 