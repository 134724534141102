/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-undef */
import { Card } from 'flowbite-react';

export default function Feature() {
  return (
   <> 
 <section class="text-gray-600 bg-blue-600 body-font">
<br></br>  <div class="container px-5 py-2 mx-auto">
    <div class="flex flex-wrap -m-4 text-center">
      <div class="p-4 sm:w-1/4 w-1/2">
      
      
     <a href='/App_development_hickter' > <Card>
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12"> 
          <img
      className="object-cover object-center"
      src="./assets/icons/smartphone (1).webp"
      alt="pic" />
          </div>
          
          <h6 className="mb-2 text-[12px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-blue-800  mt-[2px] fa-solid fa-regular fa-eye "></i> <br></br> </span>  App Development
          </h6> 
          </Card></a> 
      </div>

      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='/Website_development_hickter' > <Card>
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <img
      className="h-50 w-55 rounded-lg object-cover object-center"
      src="./assets/icons/ux.webp"
      alt="pic"
    />
          </div>
          <h6 className="mb-2 text-[12px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-blue-800  mt-[2px] fa-solid fa-regular fa-eye "></i> <br></br> </span>    Website Development   
          </h6>  
          </Card></a> 
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='/Startup_plan_hickter' > <Card>
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <img
      className="h-50 w-55 rounded-lg object-cover object-center"
      src="./assets/icons/domain (1).webp"
      alt="pic"
    />
          </div>
          <h6 className="mb-2 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-blue-800  mt-[2px] fa-solid fa-regular fa-eye "></i> <br></br> </span>   Domain & Hosting 
          </h6> 
          </Card></a>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='/Digital_marketing_hickter'  >  <Card>
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <img
      className="h-50 w-55 rounded-lg object-cover object-center"
      src="./assets/icons/social-media (1).webp"
      alt="pic"
    />
          </div>
          <h6 className="mb-2 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-blue-800  mt-[2px] fa-solid fa-regular fa-eye "></i> <br></br> </span>   Digital Marketing  
          </h6>
          
          </Card> </a><br></br>
      </div>
    </div>
    <br></br>  </div>

 
  <div class="container px-5 py-2 mx-auto">
    <div class="flex flex-wrap -m-4 text-center">
      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='/Banner_ads_hickter'  >     <Card>
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
           
          <img
      className="h-50 w-55 rounded-lg object-cover object-center" src="./assets/icons/ads.webp"
      alt="pic"
    />
          </div>
          
          <h6 className="mb-1 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-blue-800  mt-[1px] fa-solid fa-regular fa-eye "></i> <br></br> </span>    Banner Ads 
          </h6>
           
          </Card></a>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='/Video_ads_hickter'  >    <Card >
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <img
      className="h-50 w-55 rounded-lg object-cover object-center"
      src="./assets/icons/multimedia.png"
      alt="pic"
    />
          </div>
          <h6 className="mb-0 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-blue-800  mt-[1px] fa-solid fa-regular fa-eye "></i> <br></br> </span>    Video Ads 
          </h6>
         
          </Card></a>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='/Digital_marketing_hickter'  >   <Card>
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <img
      className="h-50 w-55 rounded-lg object-cover object-center"
      src="./assets/icons/seo (1).webp"
      alt="pic"
    />
          </div>
          <h6 className="mb-0 text-[11px] lg:text-[10px]  font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-blue-800  mt-[0px] fa-solid fa-regular fa-eye "></i> <br></br> </span>   SEO & Social Promotion
          </h6> 
          </Card></a>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='/Corporate_plan_hickter'  >   <Card >
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <img
      className="h-50 w-55 rounded-lg object-cover object-center"
      src="./assets/icons/professional-training.webp"
      alt="pic"
    />
          </div>
          <h6 className="mb-0 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-blue-800  mt-[0px] fa-solid fa-regular fa-eye "></i> <br></br> </span>  Company Formation 

          </h6>
          
          </Card></a>
      </div>
    </div><br></br>
  </div>
</section>

 
  </>
  )
}
  