/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../Home/Navbarsection'
import Footersection from '../Home/Footersection'
import ReactPlayer from 'react-player' 

export default function Blog1() {
  return (
   <>
   <Navbarsection/>
   <div class="p-6  text-base">
 
 
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl mb-6">
          <h1  className="mx-auto text-center  font-bold lg:text-[19px]  sm:text-3xl justify-self-auto " > What is Hickter ? </h1>
 <br></br>
 
          
            <p className="text-base text-gray-700 md:text-lg">
            Hickter ¦ Healing Life Problems From Technology & Internet.. We Develop Innovative Products ( Apps, Web & Gadgets ) from Technologies and Internet. At Hickter we Believe latest technologies can solve real human problems in Better way that being solved never before. Great ideas are nothing till it implemented and created. Join us if u trust on Innovation and Building Cool Products.



            </p>
          </div>
          
        </div>
        <div>
        <div className='mr-3 player-wrapper'>
        <div>
          <img
            className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
            src="./assets/blogs/1.webp"
            alt=""
          />
        </div>
        </div>
 
        </div>
      </div>
    </div>
 
   <Footersection/>
   </>
  )
}
