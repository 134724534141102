// eslint-disable-next-line no-unused-vars
import React from 'react'
import Iframe from 'react-iframe'

export default function agentsection() {
  return (
   <>
  
<section className=" bg-gray-100">
  <div className="mx-auto max-w-screen-xl p-4 sm:px-6">
    <div className="grid grid-cols-1 gap-x-12 gap-y-4 lg:grid-cols-5">
      <div className="lg:col-span-2 lg:py-4">
        <p className="max-w-xl text-lg">
        <img src="https://res.cloudinary.com/dailyle-company/image/upload/v1705171434/Dailyle%20Website/Delivery%20Agent/Instant_Delivery_platform_1.png" className="h-90 w-full 
    rounded --300" alt="" /> 
        </p>
        <ul><br></br>
  <li>
    <a
      href="tel:917894447766"
      className="flex items-center gap-2 border-s-[3px] border-blue-500 bg-blue-50 px-4 py-3 text-red-700"
    >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>



      <span className="text-sm font-medium"> 
       <a href="" className='text-pink-500 text-[14px]' > Choose Your Work Time 
 </a> </span>
    </a>
  </li>

  <li>
    <a href="https://www.google.com/maps?q=Dailyle&ftid=0x3a20e3934300f6c9:0x350351fb7e3ed148"
      className="flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-gray-500 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m6.115 5.19.319 1.913A6 6 0 0 0 8.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 0 0 2.288-4.042 1.087 1.087 0 0 0-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 0 1-.98-.314l-.295-.295a1.125 1.125 0 0 1 0-1.591l.13-.132a1.125 1.125 0 0 1 1.3-.21l.603.302a.809.809 0 0 0 1.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 0 0 1.528-1.732l.146-.292M6.115 5.19A9 9 0 1 0 17.18 4.64M6.115 5.19A8.965 8.965 0 0 1 12 3c1.929 0 3.716.607 5.18 1.64" />
</svg>



      <span className="text-sm font-medium"> 
       <a href="https://www.google.com/maps?q=Dailyle&ftid=0x3a20e3934300f6c9:0x350351fb7e3ed148" className='text-pink-500 text-[14px]' >   <address className="mt-2 not-italic">  Explore Location  </address>  </a> </span>
    </a>
  </li>

  <li>
    <a
      href="mailto:contact@dailyle.com"
      className="flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-gray-500 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700"
    >
   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>



      <span className="text-sm font-medium">  
       <a href="mailto:contact@dailyle.com" className='text-pink-500 text-[15px]' > Earn Money From  Day 1 </a> </span>
    </a>
  </li>
 
 
</ul>
 
      </div>

      <div className="rounded-lg bg-purple-900 p-3 shadow-lg lg:col-span-3 lg:p-1">
         
        <Iframe url="https://hickter.in/dailyle/deliveryagent/agent.html"
        width="100%"
        height="400px" 
        display="block"
        position="relative"/>

      </div>
    </div>
  </div>
</section>
   </>
  )
}
 